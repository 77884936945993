import styled from "styled-components";

import vars from "variables";

import hodl from "assets/Exchanges/hodl.svg";
import topRightBlue from "assets/Exchanges/topright-blue.svg";

const Root = styled.div`
  & > ul {
    margin: 70px 0 120px;
  }

  li.exchange {
    min-height: 400px;
    margin-top: 20px;
    position: relative;
    background: ${vars.colors.marineBlue};
    border-radius: 15px;
    z-index: 0;
  }

  .price,
  .info {
    flex: 1;
  }

  .price {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${vars.colors.background};
    border-radius: 10px;
  }

  img.logo {
    max-width: 80%;
    filter: brightness(0) invert(1);
    opacity: 0.9;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    position: absolute;
    background: ${vars.colors.marineBlue};
    border-radius: 7px;
  }

  .stats span.hodl {
    display: block;
    background: url(${hodl}) no-repeat;
  }

  small {
    padding: 1px 5px 0;
    color: ${vars.colors.marineBlue};
    font-weight: bold;
    font-size: 0.65rem;
    border-radius: 3px;
  }

  small.positive {
    background: #50e89d;
  }

  small.negative {
    background: #e85050;
  }

  .links a {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    background: url(${topRightBlue}) no-repeat center right;
  }

  .info-box {
    line-height: 20px;
    position: absolute;
  }

  .info-box a {
    padding: 6px 30px 6px 12px;
    font-size: 0.7rem;
    background-size: 14px;
  }

  .tooltip {
    background-color: rgb(33, 35, 62) !important;
    &.place-top {
      &:after {
        border-top-color: rgb(33, 35, 62) !important;
      }
    }
    &.place-right {
      &:after {
        border-right-color: rgb(33, 35, 62) !important;
      }
    }
    &.place-bottom {
      &:after {
        border-bottom-color: rgb(33, 35, 62) !important;
      }
    }
    &.place-left {
      &:after {
        border-left-color: rgb(33, 35, 62) !important;
      }
    }
  }

  ${vars.desktop.mediaQuery} {
    li.exchange {
      display: flex;
    }

    .price {
      margin: 15px;
      flex: 0 0 625px;
    }

    .stats {
      padding-right: 20px;
    }

    .stats span.hodl {
      padding-left: 53px;
      padding-right: 60px;
      background-position: center left 18px;
    }

    .info {
      padding: 60px 70px 45px 75px;
    }

    .info-box {
      right: 60px;
    }

    h3 {
      font-size: 1.7rem;
      font-weight: 600;
    }

    p {
      margin-top: 25px;
      color: ${vars.colors.grey};
      font-size: 1rem;
      line-height: 150%;
    }

    img.logo {
      max-height: 60px;
      margin-top: -30px;
    }

    .stats {
      bottom: 13px;
      left: 13px;
      right: 13px;
    }

    .stats span {
      line-height: 400%;
    }

    .stats small {
      margin-left: 7px;
    }

    .links a {
      margin-top: 28px;
      margin-right: 45px;
      padding-right: 22px;
      font-size: 0.75rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    li.exchange {
      padding-top: 1px;
    }

    .price {
      height: 200px;
      margin: 20px;
      margin-bottom: 7px;
    }

    .stats {
      bottom: 6px;
      left: 6px;
      right: 6px;
      padding: 6px 12px;
      font-size: 0.7rem;
      flex-wrap: wrap;
    }

    .stats span.hodl {
      padding-left: 27px;
      padding-right: 60px;
      background-position: center left;
      background-size: 20px;
    }

    .stats div {
      padding: 4px 0;
      flex: 0 0 calc(50% - 20px);
    }

    .stats small {
      margin-left: 5px;
    }

    .stats .right {
      margin-left: auto;
      text-align: right;
    }

    .info {
      padding: 13px 20px;
    }

    .info-box {
      right: 25px;
      bottom: 12px;
    }

    h3 {
      padding-bottom: 12px;
      font-size: 1.35rem;
      font-weight: 600;
      line-height: 140%;
    }

    p {
      margin-bottom: 15px;
    }

    img.logo {
      max-height: 35px;
      margin-top: -75px;
    }

    .links a {
      margin: 5px 25px 13px 0;
      padding-right: 12px;
      font-size: 0.6rem;
      background-size: 6px;
      background-position: top 6px right;
    }
  }
`;

export { Root };
